.terms-and-conditions-title {
    margin-top: 0;
    padding-top: 20px;
    text-align: center;
}

.legal-links-wrapper {
    margin-bottom: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.legal-link {
    background-color: var(--brand-color-red);
    color: white;
    text-decoration: none;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    border-radius: var(--border-radius);
}

.legal-wrapper {
    max-width: 40em;
    text-align: left;
    margin-left: calc(50% - 20em);
}

@media only screen and (max-width: 700px) {
    .legal-wrapper {
        max-width: 90%;
        text-align: left;
        margin-left: calc(5%);
    }
}
