.quiz-preview {
    background-color: var(--forte);
    color: white;
    padding: 10px;
    border-radius: var(--border-radius);
    cursor: pointer;
}

.quiz-preview__title {
    font-weight: 500;
}

.quiz-preview__description {
    margin-top: 5px;
    color: #bbb;
}
