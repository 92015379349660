.search-result {
    margin-top: 10px;
}

.search-results {
    z-index: 2;
    position: absolute;
    background-color: var(--piano);
    margin-top: 15px;
    right: 20px;
    width: 470px;
    padding: 15px;
    border-radius: var(--border-radius);
    opacity: 0;
    animation: 0.5s search-results-in forwards;
    box-shadow: var(--box-shadow);
}

@media only screen and (max-width: 700px) {
    .search-results {
        width: 300px;
    }
}

.search-results__label {
    margin-top: 20px;
    margin-bottom: 10px;
    color: white;
}

.select-quiz-difficulty {
    background-color: var(--forte);
    color: white;
}

.generate-quiz {
    margin-top: 10px;
    background-color: var(--brand-color-yellow);
    color: var(--forte);
}

.generate-quiz__is-generating {
    background-color: var(--mezzo);
    color: white;
}

@keyframes search-results-in {
    0% {
        opacity: 0;
        transform: scale(0.4) translateY(-60px) rotate(10deg);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0) rotate(0);
    }
}

.search-results__gen-info {
    font-weight: 300;
    font-style: italic;
    margin-top: 20px;
    color: white;
    text-align: right;
}