@font-face {
  font-display: swap;
  font-family: inter;
  font-weight: 300;
  src: url('assets/fonts/inter/Inter-Light.ttf');
}
@font-face {
  font-display: swap;
  font-family: inter;
  src: url('assets/fonts/inter/Inter-Regular.ttf');
}
@font-face {
  font-display: swap;
  font-family: inter;
  src: url('assets/fonts/inter/Inter-Bold.ttf');
  font-weight: 600;
}

* {
  --brand-color-yellow: #FFFF00;
  --brand-color-purple: #8223EC;
  --brand-color-purple__transparent: #8223ecaa;
  --brand-color-blue: #253BFE;
  --brand-color-blue__alt: #2328E8;
  --brand-color-green: #2BE289;
  --brand-color-green__alt: #1BBF6F;
  --brand-color-red: #EF4367;
  --brand-color-orange: #FC961D;
  --brand-color-light-blue: #4DA5E2;
  --forte: #111111;
  --mezzo: #333333;
  --piano: #666666;
  --pianissimo: #DEDEDE;
  --pianississimo: #F6F6F6;
  --light-grey: #eee;
  --dark: #111;
  --dark__alt: #222;
  --positive: #12AF58;
  --border-radius: 5px;
  --warning: #330000;
  --box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.1);
  --box-shadow-2: 0 0 3px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.2);
  --box-shadow-3: 0 0 3px 0 rgba(0, 0, 0, 0.4), 0 4px 5px 0 rgba(0, 0, 0, 0.4);
  --nav-height: 50px;
}

body {
  margin: 0;
  padding: 0;
  background-color: white;
  color: var(--dark);
  font-size: 0.9em;
  font-family: inter;
  font-weight: 400;
  background-color: var(--mezzo);
}

@media only screen and (max-width: 700px) {
  body {
    font-size: 1.1em;
  }
}

::selection {
  background: var(--brand-color-purple__transparent);
}
::-moz-selection {
  background: var(--brand-color-purple__transparent);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--dark) !important;
}

*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-track {
  border: 1px solid var(--brand-color-purple);
  background-color: var(--dark);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--brand-color-purple);
  outline: 1px solid var(--dark);
}

button {
  font-family: inter;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

input {
  outline: none;
}

h1 {
  user-select: none;
  font-size: 1.6em;
  font-weight: 500;
  text-align: left;
}

h2, h3, h4 {
  user-select: none;
  font-size: 1.2em;
  text-align: left;
  font-weight: 500;
}

p {
  user-select: none;
}


.app {
  min-height: 100vh;
}

@media only screen and (max-width: 700px) {
  .app {
    margin: 0px;
    font-size: 0.8em;
  }
}

.notify-user-container {
  position: fixed;
  width: calc(100% - 20px);
  max-width: calc(100% - 20px);
  bottom: 0;
  padding: 10px;
  background-color: var(--brand-color-purple);
  z-index: 15;
  animation: fade-bounce 0.5s;
  color: white;
  font-size: 0.8em;
  box-shadow: var(--box-shadow-3);
}

.notify-user-container__callback-styling {
  background-color: var(--brand-color-purple);
}

.notify-user-container__positive-styling {
  background-color: var(--positive);
}

.notify-user-container__bad-news-styling {
  background-color: var(--warning);
}

