.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: white;
}

@keyframes loads-in {
  0% {
    opacity: 0;
    filter: grayscale(1);
  }
  60% {
    opacity: 1;
    filter: grayscale(1);
  }
  80% {
    opacity: 0.8;
    filter: grayscale(1);
  }
  100% {
    opacity: 1;
    filter: grayscale(0);
  }
}

.is-loading-image {
  background-size: contain;
  background-repeat: no-repeat;
  height: 100px;
  width: 100px;
  margin-bottom: 20px;
  animation: loads-in 1.3s forwards ease-in-out;
}

