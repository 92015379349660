.adv-input-outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  user-select: none;
}

.adv-input-outer label {
  font-weight: bold;
  margin-left: 4px;
  margin-bottom: 6px;
  padding-left: 0px;
  transition: all 0.5s;
  border-left: 1px dashed transparent;
}

.adv-input-outer:focus-within label {
  padding-left: 8px;
  border-left: 1px dashed var(--piano);
}

.adv-input input {
  align-self: flex-start;
  width: calc(100% - 20px);
  flex-grow: 1;
  border-radius: var(--border-radius);
  outline: 0;
  padding: 10px;
  margin-right: 10px;
  background-color: white;
  border: 1px solid var(--mezzo);
}

.adv-input__with-information input {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.adv-input__with-error input {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.adv-input__disabled input {
  background-color: var(--pianissimo);
}

.adv-input-information {
  width: calc(100% - 18px);
  display: inline-block;
  font-size: 0.8em;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  padding: 4px 9px;
  background-color: var(--pianississimo);
  color: var(--piano);
  border: 1px solid var(--mezzo);
  border-bottom: none;
}

.adv-input-loading {
  position: absolute;
  right: 8px;
  bottom: 1px;
}

.adv-input-loading svg {
  width: 30px;
  height: 30px;
  max-width: 30px;
  max-height: 30px;
}

.adv-input-error {
  width: calc(100% - 20px);
  color: white;
  background-color: var(--brand-color-red);
  padding: 10px;
  border: 1px solid var(--mezzo);
  border-top: none;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}
