.question-container-outer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fade-in 4s forwards;
    opacity: 0;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.question-container {
    margin-top: 100px;
    width: 400px;
}

.question-container h1 {
    margin-top: 30px;
    text-transform: uppercase;
    text-align: center;
    color: white;
    font-size: 2.1em;
    font-weight: bolder;
    text-shadow: 1px 1px 2px #000;
}

.quiz-question {
}

.quiz-question-image__container {
    position: absolute;
    left: 0;
    width: 100%;
    top: calc(var(--nav-height));
    bottom: 0;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    max-height: calc(100vh - (var(--nav-height) - 30px));
    z-index: -1;
    margin-bottom: 50px;
}

.quiz-question-image {
    width: 100vw;
    background-repeat: no-repeat;
    background-size: 240%;
    border-radius: var(--border-radius);
    user-select: none;
    animation: background-image-animation 47s infinite;
}

.quiz-question-image__alt {
    animation: background-image-animation__alt 47s infinite;
}

@keyframes background-image-animation {
    0% {
        background-position: 00% 00%;
    }
    98% {
    }
    100% {
        background-position: 90% 90%;
    }
}

@keyframes background-image-animation__alt {
    0% {
        background-position: 90% 00%;
    }
    98% {
    }
    100% {
        background-position: 0% 90%;
    }
}

.quiz-question h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: var(--border-radius);
    color: var(--forte);
    font-size: 1.2em;
    background-color: rgba(255, 255, 0, 0.6);
    font-weight: bold;
    backdrop-filter: blur(10px);
    text-align: center;
    min-height: 60px;
}

.quiz-options {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.quiz-option {
    max-width: calc(50% - 10px);
    margin-bottom: 20px;
    font-size: 1.2em;
    color: white;
    font-weight: lighter;
    width: 100%;
    padding: 5px;
    border-radius: var(--border-radius);
    user-select: none;
    min-height: 120px;
    transition: background-color 0.5s;
    box-shadow: var(--box-shadow);
}

.quiz-option__no-response {
    background-color: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(15px);
}

.quiz-option__correct {
    background-color: rgba(0, 255, 0, 0.65);
}

.quiz-option__incorrect {
    background-color: rgba(255, 0, 0, 0.65);
}

.question-container-outer__random-quiz-button {
    background-color: var(--forte);
    color: var(--brand-color-yellow);
    font-weight: 600;
    padding: 20px;
}

.question-container-outer__random-quiz-button__end {
    margin-top: 30px;
}

.question-container__info {
    text-align: center;
    color: var(--brand-color-yellow);
    user-select: none;
    font-size: 1.3em;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 1px 1px 2px #000;
}

.quiz-navigation {
    margin-top: 20px;
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

.icon-container__quiz-navigation-icon {
    cursor: pointer;
}

.icon-container__quiz-navigation-icon svg path {
    fill: var(--pianississimo);
}

.quiz-navigation-icon svg path {
    fill: var(--pianississimo);
    cursor: pointer;
}

.quiz-navigation-icon__disabled svg path {
    fill: var(--piano);
}